import action from '@state/modules/dm/action'
import config from '@state/modules/dm/config'
import extensions from '@state/modules/dm/extensions'
import importModule from '@state/modules/dm/import'
import processes from '@state/modules/dm/processes'
import productActivation from '@state/modules/dm/productActivation'
import publicModule from '@state/modules/dm/public'
import search from '@state/modules/dm/search'
import uploads from '@state/modules/dm/uploads'
import usage from '@state/modules/dm/usage'

export default {
  namespaced: true,

  modules: {
    action,
    config,
    extensions,
    public: publicModule,
    import: importModule,
    processes,
    productActivation,
    search,
    uploads,
    usage,
  },
}
