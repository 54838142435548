import api from '@api'

import sapi from '@api/service'
export default {
  namespaced: true,

  state: () => ({
    billingData: [],
    billingQuery: null,
  }),

  mutations: {
    setBillingData(state, data) {
      state.billingData = data
    },
    setBillingQuery(state, query) {
      state.billingQuery = query
    },
  },

  actions: {
    /**
     * Fetch billing data for a specific period and store it in the state.
     * @param {Object} context - Vuex context object.
     * @param {String} period - The billing period to fetch (e.g., 'currentmonth').
     */
    async fetchBillingData({ commit }, period = 'currentmonth') {
      try {
        const data = await api.billing.period(period)
        commit('setBillingData', data)
        commit('setBillingQuery', period)
        return data
      } catch (err) {
        commit('setBillingData', [])
        sapi.error(err)
      }
    },
    async initializeEnabledExtensions({ dispatch }, data) {
      const enabledExtensions = {}
      const promises = data.map(async item => {
        const isActive = await dispatch(
          'dm/extensions/isActiveByName',
          item.name,
          { root: true }
        )
        enabledExtensions[item.name] = isActive
      })
      await Promise.all(promises)
      return enabledExtensions
    },
  },

  getters: {
    /**
     * Filter billing data by the given name.
     * @param {Object} state - Vuex state.
     * @returns {Function} - A function that filters billing data by name.
     */
    getBillingDataByName:
      state =>
      (name = 'generic-extraction') => {
        return state.billingData.filter(el => el.name === name)
      },
  },
}
