import store from '@state/store'

import keycloakAuth from './auth/keycloak-auth'
import zitadelAuth from './auth/zitadel-auth'

const AUTH_METHOD_KEYCLOAK = 'kc'
const AUTH_METHOD_ZITADEL = 'z'

class AuthService {
  get authMethod() {
    return store.state.app.login.authMethod
  }

  get authenticated() {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.authenticated
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.authenticated
      default:
        return false
    }
  }

  set authenticated(a) {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        keycloakAuth.authenticated = a
        break
      case AUTH_METHOD_ZITADEL:
        zitadelAuth.authenticated = a
        break
    }
  }

  get ready() {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.ready
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.ready
      default:
        return true
    }
  }

  set ready(r) {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        keycloakAuth.ready = r
        break
      case AUTH_METHOD_ZITADEL:
        zitadelAuth.ready = r
        break
    }
  }

  get token() {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.token
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.token
      default:
        return ''
    }
  }

  get tokenParsed() {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.tokenParsed
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.tokenParsed
      default:
        return {}
    }
  }

  get refreshToken() {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.refreshToken
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.refreshToken
      default:
        return ''
    }
  }

  async initialize(customer) {
    await this.load(customer)
  }

  async reinitialize(customer) {
    await this.load(customer)
  }

  async load(customer, token, refreshToken, clientId) {
    if (!customer) {
      return
    }
    if (!this.authMethod) {
      store.commit('app/login/authMethod', AUTH_METHOD_KEYCLOAK)
    }
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.load(customer, token, refreshToken, clientId)
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.load(customer, token, refreshToken, clientId)
    }
  }

  async login(options) {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.login(options)
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.login(options)
      default:
        window.location.href = window.location.origin + '/login'
    }
  }

  async loginCallback() {
    switch (this.authMethod) {
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.loginCallback()
    }
  }

  async logout(options) {
    switch (this.authMethod) {
      case AUTH_METHOD_KEYCLOAK:
        return keycloakAuth.logout(options)
      case AUTH_METHOD_ZITADEL:
        return zitadelAuth.logout(options)
    }
  }

  clearToken() {
    keycloakAuth.clearToken()
    zitadelAuth.clearToken()
  }

  async validateStoredCustomer() {
    const customer = store.state.app.login.customer
    if (!customer) {
      return
    }
    // Validation is only necessary for Keycloak, because
    // an unknown customer prevents the app from loading.
    if (!this.authMethod || this.authMethod === AUTH_METHOD_KEYCLOAK) {
      return keycloakAuth.validateCustomer(customer)
    }
    return customer
  }
}

const auth = new AuthService()

export default auth
