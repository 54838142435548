import sapi from '@api/service'
import { getEnvVar } from '@utils/env'

const endpoint = 'login'

/**
 * Module for login process
 * Holds information of a user during login process
 */
export default {
  namespaced: true,

  state: () => ({
    username: '',
    customer: '',
    tenant: '',
    hint: '',
    idp: 'dm-azure',
    authMethod: '',
    redirectTo: '',
    zissuer: '',
    zids: {},
  }),

  mutations: {
    username(state, username) {
      state.username = username
      localStorage.setItem('login/username', username)
    },
    customer(state, customer) {
      state.customer = customer
      localStorage.setItem('login/customer', customer)
    },
    tenant(state, tenant) {
      state.tenant = tenant
      localStorage.setItem('login/tenant', tenant)
    },
    hint(state, user) {
      state.hint = user
    },
    idp(state, idp) {
      state.idp = idp
      localStorage.setItem('login/idp', idp)
    },
    authMethod(state, authMethod) {
      state.authMethod = authMethod
      localStorage.setItem('login/authMethod', authMethod)
    },
    redirectTo(state, redirectTo) {
      state.redirectTo = redirectTo
      localStorage.setItem('login/redirectTo', redirectTo)
    },
    zissuer(state, zissuer) {
      state.zissuer = zissuer
      localStorage.setItem('login/zissuer', zissuer)
    },
    zids(state, zids) {
      state.zids = zids
      localStorage.setItem('login/zids', JSON.stringify(zids))
    },
  },

  actions: {
    init({ commit }) {
      const username = localStorage.getItem('login/username')
      if (username) {
        commit('username', username)
        commit('hint', username)
      }
      const cust = localStorage.getItem('login/customer')
      if (cust) {
        commit('customer', cust)
      }
      const tenant = localStorage.getItem('login/tenant')
      if (tenant) {
        commit('tenant', tenant)
      }
      const authMethod = localStorage.getItem('login/authMethod')
      if (authMethod) {
        commit('authMethod', authMethod)
      }
      const redirectTo = localStorage.getItem('login/redirectTo')
      if (redirectTo) {
        commit('redirectTo', redirectTo)
      }
      const idp = localStorage.getItem('login/idp')
      if (idp) {
        commit('idp', idp)
      }
      const issuer = localStorage.getItem('login/zissuer')
      if (issuer) {
        commit('zissuer', issuer)
      }
      const zIds = localStorage.getItem('login/zids')
      if (zIds) {
        try {
          commit('zids', JSON.parse(zIds))
        } catch (ignore) {}
      }
    },
    idps(_, { customer, emptyTenant }) {
      const headers = { 'X-es-customer': customer }
      if (emptyTenant) {
        headers['X-es-tenant'] = undefined
      }
      return sapi.landlord
        .get(`${endpoint}/identity-providers`, {
          headers,
        })
        .then(res => {
          return res.data || []
        })
        .catch(err => {
          if (sapi.isStatus(err, 404)) {
            return Promise.resolve([])
          } else {
            sapi.error(err)
            return Promise.reject(
              new Error('could not fetch idps due to err: ' + err)
            )
          }
        })
    },
    customer({ commit, state }, { tenant, username }) {
      let tenantPath = ''
      if (state.tenant && state.tenant !== '') {
        tenantPath = `/${state.tenant}`
      } else if (tenant) {
        tenantPath = `/${tenant}`
      }
      return sapi.landlord
        .get(`${endpoint}/customer${tenantPath}`, {
          headers: { 'X-es-username': username },
          omitError: true,
        })
        .then(res => {
          res.data.issuer = getEnvVar('VUE_APP_URL')
          commit('authMethod', res.data.authMethod || 'kc')
          commit('customer', res.data.id || '')
          commit('username', username || '')
          commit('hint', username || '')
          commit('idp', res.data.idp || '')
          commit('zids', res.data.zitadel)
          commit('zissuer', res.data.issuer || '')
          return res.data
        })
        .catch(err => {
          commit('customer', '')
          if (sapi.isStatus(err, 404)) {
            return Promise.resolve('')
          } else {
            sapi.error(err)
          }
        })
    },
  },
}
